.Round-overview {
    border-radius: 11px;
    background-color: white;
    width: 320px;
    margin-bottom: 20px;
    box-shadow:
        0 1px 1px rgba(0, 0, 0, 0.1),
        0 2px 2px rgba(0, 0, 0, 0.1),
        0 4px 4px rgba(0, 0, 0, 0.1),
        0 8px 8px rgba(0, 0, 0, 0.1);
}

.Round-padding-top-bottom {
    padding-top: 15px;
    padding-bottom: 15px;
}

.Round-padding-left-right {
    padding-left: 15px;
    padding-right: 15px;
}

.Round-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
}

.Shot-horizontal-scroll {
    height: 50px;
    overflow-y: scroll;
    display: flex;
}

.Shot-item {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    font-size: 15px;
    border-radius: 50px;
    color: #0b1525;
    background: #eef4f7;
}

.Shot-selected {
    background: #0b1525;
    color: #eef4f7;
}

.Round-image-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 50%;
    top: 50px;
    transform: translate(-50%, 0%);
    color: white;
    background: #666666;
    z-index: 1000;
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow:
        0 1px 1px rgba(0, 0, 0, 0.1),
        0 2px 2px rgba(0, 0, 0, 0.1),
        0 4px 4px rgba(0, 0, 0, 0.1),
        0 8px 8px rgba(0, 0, 0, 0.1);
}

@media (max-height: 900px) {
    .Round-image {
        height: 200px;
    }
}

.Round-device-scroll {
    max-height: calc(100vh - 860px);
    overflow-y: scroll;
}
.Round-device-scroll-full-height {
    max-height: calc(100vh - 500px);
    overflow-y: scroll;
}

.Round-updated-by {
    font-size: 13px;
    color: #606f7b;
    font-weight: bold;
    margin-bottom: 0.1rem;
    margin-top: 0.25rem;
}

.Round-selected-gunshot {
    background: white;
    position: absolute;
    width: 100%;

    box-shadow:
        0 1px 1px rgba(0, 0, 0, 0.1),
        0 2px 2px rgba(0, 0, 0, 0.1),
        0 4px 4px rgba(0, 0, 0, 0.1),
        0 8px 8px rgba(0, 0, 0, 0.1);
}

.Round-device-title-and-filter {
    display: flex;
    justify-content: space-between;
}
.Round-filters {
    display: flex;
}

.Round-filters > p {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    font-size: 13px;
    margin-bottom: 0;
    color: #0b1525;
    text-decoration: underline;
    cursor: pointer;
}

.Round-button {
    cursor: pointer;
    background: #0b1525;
    padding: 7px 0;
    color: white;
    font-size: 13px;
    border-radius: 20px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.Round-adjustable-button {
    color: #0b1525;
    background: #ffffff;
    border: solid 1px #0b1525;
}

.Round-selected {
    font-weight: bold;
}

.Round-label {
    color: #0b1525;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 1px;
}

.Round-audio-section {
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
}

.Round-extra-controller {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Round-input {
    padding: 5px;
    border-radius: 5px;
    border: #ccc solid 1px;
}

.Round-ammo-input {
    padding: 10px;
    margin: 0.5rem;
    margin-top: 0;
    border-radius: 5px;
    width: 50px;
    font-size: 16px;
    text-align: center;
    border: #ccc solid 0.5px;
}

.Round-subheader {
    font-size: 15px;
    color: #0b1525;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0;
    text-decoration: none;
}

.selected {
    background: #eef4f7;
}

.Round-title {
    font-size: 13px;
    margin-bottom: 0;
    color: black;
    color: #0b1525;
    font-weight: bold;
}

.Round-top-title {
    font-family: "Titillium Web", sans-serif;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: bold;
    color: #0b1525;
    margin-top: 0;
}

.Round-subheader-span {
    font-size: 11px;
    opacity: 0.6;
    color: black;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 0;
    text-decoration: none;
}

.Round-section {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Round-top-bottom-padding {
    padding-top: 20px;
    padding-bottom: 20px;
}

.Round-small-section {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}

.Round-item {
    margin: 0 0 5px 0;
    font-size: 13px;
    color: hsl(207, 12%, 43%);
    font-weight: bold;
}

.Round-subsubheader {
    font-size: 13px;
    color: black;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0;
    text-decoration: none;
}

.Overlay-centered-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}
