
.Dashboard-clickable-text {
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: bold;
  cursor: pointer;
  color: #4A90E2;
}
.Dashboard-clickable-text-selected {
  background-color: #eef4f7;
}

.Dashboard-action-button {
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #4A90E2;
}

.Dashboard-no-decoration {
  text-decoration: none;
}


.Dashboard-header {
  background: #0B1525;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 25px;
  padding-bottom: 10px;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Dashboard-header-title {
  color: white;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 25px;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.Dashboard {
  height: 100vh;
}

.Dashboard-analyzer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Dashboard-analyzer-round-content {
  padding: 1rem;
  margin: 0.2rem;
  flex-basis: 200px;
  flex-grow: 1;
  background-color: #eef4f7;
  cursor: pointer;
}

.Dashboard-analyzer-round-title {
  font-size: 14px;
  font-weight: bold;
  color: #0B1525;
}

.Dashboard-analyzer-round-small-desc {
    font-size: 10px;
    opacity: 0.8;
    font-weight: bold;
    color: #0B1525;
    margin: 0;
}

.Dashboard-stack {
  flex-basis: 300px;
  max-width: 600px;
  flex-grow: 1;
}

.Dashboard-commander-login {
  flex-basis: 500px!important;
  flex-grow: 2;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Dashboard-incident {
  flex-basis: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}
.Dashboard-round {
  flex-basis: 300px;
  max-width: 500px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Dashboard-logout {
  margin-right: 30px;
}

.RoundWidget-hr {
  border: rgba(184, 194, 204, 0.49) solid 0.5px;
  width: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.GunshotStatistics-item {
  display: flex;
  flex-wrap: nowrap;
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem 0.5rem;
}

.GunshotStatistics-count {
  font-size: 2.5rem;
  margin: 0rem 0.5rem;
  font-weight: 500;
  /* font-weight: bold; */
}

.GunshotStatistics-label {
  font-size: 1rem;
  font-weight: bold;
  flex-wrap: wrap;
  max-width: 5rem;
}

.Dashboard-search-section {
  flex-basis: 30rem;
  flex-grow: 1;
  max-width: 40rem !important;
}

@keyframes Dashboard-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, -2%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
