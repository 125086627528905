.App {
  text-align: center;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-content {
  display: flex;
  justify-content: center;
  animation: fadeIn ease-in-out 1s forwards;
}

.App-header {
  color: white;
  animation: fadeIn ease-in-out 1s forwards;
}

.App-title {
  color: #53c68c;
}

.App-container {
  width: 400px;
  border-radius: 15px;
  padding: 30px;
}


.App-login-row {
  display: flex;
  justify-content: space-between;
  width:100%;
  margin-left: -4rem;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-username {
  flex-grow: 2;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.App-link {
  color: #61dafb;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  color: #0B1525;
  margin-top: 10px;
  text-decoration: none;
}

.App {
  background: #0B1525;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, -2%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
