.relativeContainer {
  margin-top: 0rem;
  width: 100%;
  height: 4rem;
}

.items {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  margin-left: 1rem;
  margin-top: -0.125rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: white;
  }
}

.styleOne {
  border-radius: 25px;
  background-color: #eef4f7;
}

.styleTwo {
  border: 1px solid #8795a1;
  border-radius: 5px;
}

.selectedDrop {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;

  cursor: pointer;

  p {
    font-weight: bold;
    color: hsl(207, 12%, 43%);
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.5rem;
    margin-right: 0.25rem;
    font-size: 1rem;
    white-space: nowrap;
  }
  div {
    margin-bottom: 1;
  }
}
