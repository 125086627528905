.AmbientNoiseWidget-container {
  margin-top: 10px;
}

.AmbientNoiseWidget-progressBar {
  width: 130px;
  height: 20px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.AmbientNoiseWidget-container {
  display: flex;
  align-items: center;
}

.AmbientNoiseWidget-label {
  font-size: 0.8rem;
  font-weight: bold;
}
