.Organisation-content {
  max-width: 920px;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.Graph-content {
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10rem;
  padding-right: 10rem;
}

.Organisation-details {
  div {
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .Organisation-name {
    font-size: 2.25rem;
  }
}

.Organisation-devices {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Organisation-summary-name {
  font-weight: 600;
}

.Organisation-summary-count {
  font-weight: 600;
  font-size: 3rem;
}

.Organisation-section {
  margin-top: 3rem;
}

.Organisation-name-and-details {
  p {
    margin: 0.125rem;
  }
  p:nth-child(1) {
    font-weight: 600;
  }
  p:nth-child(2) {
    font-weight: normal;
    opacity: 0.8;
    font-size: 0.75rem;
  }
}

.Device-online {
  border: 3px solid 
  #A7F3D0;
}

.Device-offline {
  border: 3px solid 
  #A7F3D0;
}

.Organisation-status-section {
  display: flex;
  justify-content: space-between;
  max-width: 37rem;
  margin-left: auto;
  margin-right: auto;
  
}

.Organisation-device-status {
  margin-top: 2rem;
  p:first-child{
    font-weight: bold;
  }
  p {
    font-weight: normal;
    margin: 0.125rem;
  }
}

.Organisation-key-value {
  display: flex;
  flex-wrap: wrap;
  p {
    margin: 0.125rem;
  }
  p:first-child {
    font-weight: 500;
    opacity: 0.7;
  }
  p:last-child {
    font-weight: bold;
    margin-left: 0.25rem;
    opacity: 1 !important;
  }

}

.Organisation-status-summary {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }
  p:nth-child(1) {
    font-weight: 600;
  }
  p:nth-child(2) {
    font-weight: normal;
    font-size: 3rem;
  }
}

.Organisation-device-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;

  p {
    margin: 0;
  }
  p:nth-child(1) {
    font-weight: normal;
    font-size: 0.75rem;
  }
  p:nth-child(2) {
    font-weight: bold;
    font-size: 1.25rem;
  }
}

.Organisation-orderby {
  
  display: flex;
  align-items: center;
p {
  margin: 0;

}

}

.Organisation-devices-header {
  margin: 1rem 2rem;
}



