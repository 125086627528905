.Settings-section {
  display: flex;
  align-items: center;
  padding: 2rem;
}

.Settings-content {
  max-width: 1280px;
}

.Settings-section-title {
  color: #0B1525;
  font-size: 1.5em;
  letter-spacing: 1px;
  opacity: 0.8;
  margin-left: 2rem;
}

.System-widget {
  background: white;
  border-radius: 5px;
  margin: 1rem 2rem;
  padding: 1rem 3rem 1.5rem 3rem;
  flex-basis: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ConfigStore-properties-and-field {
  display: flex;
  justify-content: space-between;
}

.ConfigStore-area {
  resize: vertical;
  width: 100%;
  height: 500px;
  min-width: 400px;
}

.ConfigStore-area-wrap {
  flex-grow: 1;
  margin-left: 2rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

