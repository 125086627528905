.TimingCategory-container {
  left: 20px;
  bottom: 550px;
  position: absolute;
  z-index: 10;
  background-color: white;
  border-radius: 11px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 10%),
    0 4px 4px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 10%);
  padding: 1rem;
}

.TimingCategory-close {
  text-align: right;
  opacity: 0.3;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

@media (max-height: 1000px) {
  .TimingCategory-container {
    height: 350px;
    overflow-y: scroll;
  }
}
