.Player {
  padding-top: 100px;
}

.Player-container {
  height: 600px;
  width: 100vw;
}

.Player-minimap-container {
  height: 50px;
  width: 100vw;
}

.Player-timeline {
  /*height: 100px;*/
  /*width: 100vw;*/
}

.Incident-page {
  width: 100vw;
}

.Player-main-wave {
  width: 100%;
  height: 200px;
}

.Incident-item {
  margin-bottom: 30px;
  border-radius: 20px;
  /*box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.05), 0 8px 8px rgba(0, 0, 0, 0.05);*/
}

.Incident-item-relative {
  position: relative;
  margin-bottom: -30px;
}

.Incident-loading-status {
  top: 150px;
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  color: hsl(210, 16%, 76%);
}

.Incident-peak-info {
  top: 225px;
  display: flex;
  position: relative;
  z-index: 99;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 15px;
  margin-right: 10px;
  color: hsl(210, 16%, 76%);
}

.Incident-classification-info {
  display: flex;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 13px;
  color: hsla(210, 16%, 76%, 0.57);
}

.Incident-download-link {
  font-size: 13px;
  color: hsl(207, 12%, 43%);
  font-weight: normal;
  cursor: pointer;
  margin: 10px 0 5px;
  text-decoration: underline;
}

.Incident-expanded {
  display: flex;
}

.Incident-right-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Incident-fade {
  animation: background-fade 2s ease-out;
}

@keyframes background-fade {
  0% {
    background: #e2e8f0;
  }
  100% {
    background: white;
  }
}

@media (max-width: 640px) {
  .Incident-item {
    width: 100%;
  }
}

.Incident-main-wave {
  width: 100%;
  height: 200px;
}

.Incident-sample {
  font-size: 15px;
  color: hsl(207, 12%, 43%);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
}

.Incident-time {
  font-size: 13px;
  color: hsl(210, 16%, 76%);
  font-weight: bold;
}

.Incident-tags {
  display: flex;
  margin-bottom: 10px;
}

.Incident-peak-text {
  font-size: 13px;
  color: hsl(207, 12%, 43%);
  font-weight: bold;
}

.Incident-tag {
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: hsl(207, 12%, 43%);
  background: #eef4f7;
  font-weight: bold;
}

.Incident-tag-filtered {
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
  background: #0B1525;
  font-weight: bold;
}

.Incident-action-button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  padding: 1rem;
}


.Incident-main-controllers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Incident-slider {
  margin-left: 20px;
}

.Incident-info {
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Incident-controller {
  padding: 10px 20px;

  background: hsla(200, 50%, 98%, 1);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Incident-page {
  margin: auto;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  top: -30px;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  right: 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (min-width: 960px) {
  .Incident-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 960px;
  }
}

@media (min-width: 1680px) {
  .Incident-page {
    width: 1680px;
  }
}
