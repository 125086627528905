.Outlier-title {
    color: black;
}

.Outlier-items {
    display: flex;
    flex-wrap: wrap;
    color: black;
}


.Outlier-item {
    cursor: pointer;
    border-radius: 20px;
    padding: 0.2rem 1rem;
    
    background-color: hsl(208, 21%, 88%);
    margin: 0.2rem;
}