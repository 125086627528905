$colors-grey-03: hsla(200, 50%, 98%, 1);
$colors-grey-05: #eef4f7;
$colors-grey-10: hsl(208, 21%, 88%);
$colors-grey-20: hsl(210, 16%, 76%);
$colors-grey-30: hsl(208, 12%, 58%);
$colors-grey-40: hsl(207, 12%, 43%);
$colors-grey-50: hsl(209, 15%, 28%);
$colors-grey-60: hsl(207, 29%, 21%);
$colors-grey-90: hsl(207, 76%, 3%);
$colors-primary: #0B1525;
$colors-primary-bright: hsla(205, 52%, 40%, 1);
$colors-green: #029959;

.Widget-description {
  font-size: 1rem;
  color: hsl(207, 29%, 21%);
}
.Widget-title {
  color: $colors-primary;
  font-size: 1.2rem;
  font-weight: 500;
}

.Widget-section {
  display: flex;
  align-items: center;
  padding: 2rem;
}

.Widget-content {
  // max-width: 1280px;
}

.Widget-section-title {
  color: $colors-green;
  font-size: 1.5em;
  letter-spacing: 1px;
  margin-left: 4rem;
}

.Widget-item {
  background: white;
  border: 1px solid hsl(208, 21%, 88%);
  border-radius: 7px;
  margin: 1rem 1rem;
  padding: 1rem 1rem 1.5rem 2rem;
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.Widget-small-item {
  background: white;
  border: 1px solid hsl(208, 21%, 88%);
  border-radius: 7px;
  margin: 1rem 1rem;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  flex-grow: 1;
}
.Widget-forms {
  display: flex;
}

.Widget-items {
  display: flex;
  flex-wrap: wrap;
}

.Widget-row {
  display: flex;
  justify-content: space-between;
  .Widget-key {
    font-weight: normal;
  }
  .Widget-value {
    margin-left: 5rem;
    font-weight: bold;
  }
}

.Widget-widgets {
  display: flex;
  flex-wrap: wrap;
}

.Widget-form {
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
  p {
    font-weight: bold;
    font-size: 0.9rem;
    color: $colors-primary;
    margin-bottom: 0.2rem;
  }
  input {
    font-size: 1rem;
    text-align: right;
    padding: 0.3rem;
    width: 5rem;
    border-radius: 5px;
    height: 1rem;
    margin-bottom: 1rem;
  }
}

.Widget-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: $colors-primary;
  margin-bottom: 0.2rem;
}

.Widget-description {
  font-weight: normal;
  font-size: 1rem;
  color: black;
  margin-top: 0;
}

.Widget-dropdown {
  height: 2rem;
  border-radius: 10px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 1rem;
}

